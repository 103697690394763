import React, { useContext } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Stack, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AuthContext from '../context/AuthContext';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';






const LinkCard = ({ id, data, onTagClick, onEdit, onDelete, isEditMode }) => {
  const { user } = useContext(AuthContext);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef, // Ref for the drag handle
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative',
  };

  const handleEditClick = () => {
    onEdit(data);
  };

  const handleDeleteClick = () => {
    onDelete(data.id);
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes}
      sx={{
        maxWidth: 345,
        margin: 'auto',
        position: 'relative',
      }}
    >
      {/* Card Content */}
      <a href={isEditMode ? null : data.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <Card
          sx={{
            margin: 'auto',
            borderRadius: 3,
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            background: 'rgba(255, 255, 255, 0.6)',
            backgroundImage: `url(${data.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            pointerEvents: isEditMode ? 'none' : 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 0 auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CardContent>
              <Typography component="div" variant="h5">
                {data.title}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                {data.created_on}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </a>

      {/* Tags */}
      <Stack direction="row" spacing={1} sx={{ mt: 1, justifyContent: 'center' }}>
        {data.tags?.map((tag, index) => (
          <Chip
            key={index}
            label={`#${tag}`}
            onClick={() => onTagClick(tag)}
            sx={{ cursor: 'pointer' }}
          />
        ))}
      </Stack>

      {/* Edit and Delete Buttons */}
      {isEditMode && (
        <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }}>
          <IconButton onClick={handleEditClick} aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDeleteClick} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Box>
      )}

      {/* Drag Handle */}
      {isEditMode && (
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 20,
            cursor: 'grab', // Change cursor to indicate draggable area
          }}
          ref={setActivatorNodeRef} // This ref is used to designate the drag handle
          {...listeners} // Attach listeners only to the handle
        >
          <DragHandleIcon />
        </Box>
      )}
    </Box>
  );
};

export default LinkCard;
