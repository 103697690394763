// FollowButton.js
import React from 'react';
import Button from '@mui/material/Button';
import BASEURL from '../context/ApiEndpoints';
import AuthContext from '../context/AuthContext';

const FollowButton = ({ username, isFollowing, onFollowToggle }) => {
  const { authTokens } = React.useContext(AuthContext);

  const toggleFollow = async () => {
    try {
      const response = await fetch(`${BASEURL}profile/user/${username}/toggle_follow/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authTokens?.access}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to toggle follow status');
      }

      const data = await response.json();
      onFollowToggle(data.is_following);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Button variant="contained" onClick={toggleFollow}>
      {isFollowing ? 'Unfollow' : 'Follow'}
    </Button>
  );
};

export default FollowButton;
