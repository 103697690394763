
import { createTheme } from "@mui/material/styles";

import { lightBlue, pink, grey, blueGrey,} from '@mui/material/colors';
import { PaletteMode } from '@mui/material';


const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Palette values for light mode
          primary: lightBlue,
          secondary: pink,
          divider: lightBlue[200],
          background: {
            default: grey[900],
            paper: '#fff',
          },
          text: {
            primary: blueGrey[900],
            secondary: blueGrey[700],
          },
        }
      : {
          // Palette values for dark mode
          primary: lightBlue,
          secondary: pink,
          divider: pink[700],
          background: {
            default: blueGrey[900],
            paper: blueGrey[800],
          },
          text: {
            primary: '#fff',
            secondary: grey[400],
          },
        }),
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.2rem',
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.8rem',
      lineHeight: 1.3,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: mode === 'light' ? '0px 4px 20px rgba(0, 0, 0, 0.05)' : '0px 4px 20px rgba(0, 0, 0, 0.3)',
        },
      },
    },
  },
});




const Theme = createTheme(getDesignTokens('light'));
 
  

export default Theme;