import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('access');
    console.log('Access Token:', accessToken); 
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refresh');
      console.log('Refresh Token:', refreshToken); // Log refresh token
      if (refreshToken) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/token/refresh/`, { refresh: refreshToken });
          localStorage.setItem('access', response.data.access);
          api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
          return api(originalRequest);
        } catch (tokenRefreshError) {
          console.error('Failed to refresh token', tokenRefreshError);
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
          window.location.href = '/login'; // Redirect to login
        }
      } else {
        window.location.href = '/login'; // Redirect to login
      }
    }
    return Promise.reject(error);
  }
);

export default api;
