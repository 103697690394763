import React, { useState, useEffect, useContext } from 'react';
import BASEURL from '../context/ApiEndpoints';
import AuthContext from '../context/AuthContext';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import api from '../context/ApiInstance';
import { useParams } from 'react-router-dom';

const CreateLink = ({ initialData = null, onSave }) => {
    const { authTokens, user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({});
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (initialData) {
            setTags(initialData.tags || []);
        }
    }, [initialData]);

    const handleTagDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTagAddition = (tag) => {
        setTags([...tags, tag.text]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setApiError(null);
        setFieldErrors({});

        try {
            const formData = new FormData();
            formData.append('title', e.target.title.value);
            formData.append('description', e.target.description.value);
            formData.append('url', e.target.url.value);
            formData.append('tags', tags);

            const imageFile = e.target.image.files[0];
            if (imageFile) {
                formData.append('image', imageFile);
            }

            const url = initialData
                ? `${BASEURL}content/post/${initialData.id}/`
                : `${BASEURL}content/post/`;
            const method = initialData ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Authorization': 'Bearer ' + authTokens?.access,
                },
                body: formData
            });
            navigate(`/${user.username}`);
            
            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.detail) {
                    throw new Error(errorData.detail);
                } else {
                    setFieldErrors(errorData);
                }
                throw new Error(`API request failed with status ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Posted data:', responseData);

            
      
        } catch (error) {
            setApiError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            {isLoading && <div>Loading...</div>}
            {apiError && <div>Error: {apiError.message}</div>}

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    {initialData ? 'Edit Link' : 'Post Link'}
                </Typography>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        defaultValue={initialData ? initialData.title : ''}
                        error={Boolean(fieldErrors.title)}
                        helperText={fieldErrors.title}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="description"
                        label="Description"
                        type="text"
                        id="description"
                        defaultValue={initialData ? initialData.description : ''}
                        error={Boolean(fieldErrors.description)}
                        helperText={fieldErrors.description}
                    />

                    <TextField
                        required
                        fullWidth
                        name="url"
                        label="URL"
                        type="text"
                        id="url"
                        defaultValue={initialData ? initialData.url : ''}
                        error={Boolean(fieldErrors.url)}
                        helperText={fieldErrors.url}
                    />

                    <ReactTags
                        tags={tags.map((tag, index) => ({ id: tag, text: tag }))}
                        handleDelete={handleTagDelete}
                        handleAddition={handleTagAddition}
                        placeholder="Add tags"
                    />
                    {fieldErrors.tags && <div style={{ color: 'red' }}>{fieldErrors.tags}</div>}
                    
                    <input
                        type="file"
                        accept="image/jpeg,image/png,image/gif"
                        name="image"
                        id="image"
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="image">
                        <Button component="span" variant="contained" sx={{ mt: 2 }}>
                            Upload Image
                        </Button>
                    </label>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {initialData ? 'Save Changes' : 'Post'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default CreateLink;
