import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import {jwtDecode} from 'jwt-decode'; // Korrekte Importanweisung
import { useNavigate } from 'react-router-dom';
import BASEURL from './ApiEndpoints';
import api from './ApiInstance';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null);
  const [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
  const [loading, setLoading] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  const register = async (e) => {
    e.preventDefault();
    setFieldErrors({});
    setApiError('');

    try {
        const response = await api.post('profile/register/', {
            username: e.target.username.value,
            password: e.target.password.value,
            password2: e.target.password2.value,
            email: e.target.email.value,
        });

        const data = response.data;

        if (response.status === 201) {
          localStorage.setItem('authTokens', JSON.stringify(data.tokens));
          setAuthTokens(data.tokens);
          setUser(jwtDecode(data.tokens.access));
          navigate('/');
        } else {
          throw new Error('Registration failed');
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setFieldErrors(error.response.data);
        } else {
          console.error('Registration error:', error);
          setApiError('Something went wrong while registering the user.');
        }
      }
    };


  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('token/', {
        username: e.target.username.value,
        password: e.target.password.value,
      });
      const data = response.data;
      if (response.status === 200) {
        localStorage.setItem('authTokens', JSON.stringify(data));
        setAuthTokens(data);
        setUser(jwtDecode(data.access));
        navigate('/');
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      alert('Something went wrong while logging in the user!');
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('authTokens');
    setAuthTokens(null);
    setUser(null);

  };

  const updateToken = async () => {
    try {
      const response = await fetch(`${BASEURL}token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: authTokens?.refresh })
      });

      const data = await response.json();

      if (response.ok) {
        setAuthTokens(data);
        setUser(jwtDecode(data.access));
        localStorage.setItem('authTokens', JSON.stringify(data));
      } else {
        logoutUser();
      }

      if (loading) {
        setLoading(false);
      }
    } catch (error) {
      logoutUser();
    }
  };


  useEffect(() => {
    if (loading) {
      updateToken();
    }

    const REFRESH_INTERVAL = 1000 * 60 * 100; // 10 Minuten
    const interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [authTokens, loading]);

  const contextData = {
    fieldErrors,
    apiError,
    user,
    authTokens,
    loginUser,
    logoutUser,
    register,

  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
