// ProfileCard.js
import * as React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FollowButton from './FollowButton';
import AuthContext from '../context/AuthContext';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';


const ProfileCard = ({ data }) => {
  const [isFollowing, setIsFollowing] = React.useState(data.is_following);
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleFollowToggle = (newStatus) => {
    setIsFollowing(newStatus);
  };

  return (
    <Card sx={{ maxWidth: 345, margin: 'auto',backgroundColor:'rgba(255, 255, 255, 0.7)', borderRadius: 3, position: 'relative' }}>
      <Grid item sx={{ p: "1.5rem 0rem", textAlign: "center" }}>
        <Badge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Avatar sx={{ width: 100, height: 100, mb: 1.5 }} src={data.image}></Avatar>
        </Badge>
        <Typography variant="h6">{data.user.username}</Typography>
        <Typography color="text.secondary">{data.about_me}</Typography>
        <Divider />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Typography variant="h9">{data.follower_count} Follower</Typography>
          </Grid>
          <Grid item xs={6}>
          {data.user.username === user?.username && (
            <Button onClick={() => { navigate(`/profile/${user.username}`); }}variant="contained" color="primary">Edit Profile</Button>
            )}
            {data.user.username !== user?.username && (
            <FollowButton username={data.user.username} isFollowing={isFollowing} onFollowToggle={handleFollowToggle} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProfileCard;
