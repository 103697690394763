import React, { useState, useEffect, useContext } from 'react';
import { Container, Chip, Stack, Button, CircularProgress, Typography, Box, Grid ,FormControlLabel, Switch} from '@mui/material';
import AuthContext from '../context/AuthContext';
import LinkCard from '../components/Links';
import api from '../context/ApiInstance';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';

const Explorerpage = () => {
  const { authTokens } = useContext(AuthContext);
  const [tags, setTags] = useState([]);
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterFollowing, setFilterFollowing] = useState(false);

  const handleClickTag = (tag) => {
    setSelectedTag(tag);
    setCurrentPage(1);
  };

  const toggleFilterFollowing = () => {
    setFilterFollowing((prev) => !prev);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchLinks = async () => {
      setIsLoading(true);
      setApiError(null);

      try {
        const url = `content/explorer/?page=${currentPage}&filter_following=${filterFollowing}${selectedTag ? `&tag=${selectedTag}` : ''}`;
        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${authTokens?.access}`,
          },
        });

        if (response.status !== 200) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        const linksData = response.data;
        console.log('Fetched links data:', linksData);
        setLinks(linksData.links || []);
        setTags(linksData.tags || []);
        setTotalPages(Math.ceil((linksData.count || 0) / 10));
      } catch (error) {
        setApiError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLinks();
  }, [selectedTag, currentPage, filterFollowing, authTokens]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center"  alignContent= "baseline" columnSpacing={{ xs: 1, md: 3 }}>
        <Grid item xs={12}>
        <Stack direction="row" spacing={1} justifyContent="center" my={2} alignItems="center" flexWrap="wrap">
            <FormControlLabel
              control={<Switch checked={filterFollowing} onChange={toggleFilterFollowing} color="primary" />}
              label={filterFollowing ? 'Followers Only' : 'All Users'}
            />
            <FilterAltOffOutlinedIcon onClick={() => handleClickTag(null)} label="Clear Tag Filter" />
              
            {tags.map((tag, index) => (
              <Chip key={index} onClick={() => handleClickTag(tag.tags__name)} label={tag.tags__name} />
            ))}
           
          </Stack>
        </Grid>
        <Grid item xs={2}>  </Grid>
        <Grid item xs={8}>
          <Container>
            {isLoading ? (
              <Box display="flex" justifyContent="center" my={4}>
                <CircularProgress />
              </Box>
            ) : (
              links.length > 0 ? (
                <Stack spacing={2} justifyContent="center">
                  {links.map((link, index) => (
                    <LinkCard key={index} data={link} onTagClick={handleClickTag} />
                  ))}
                </Stack>
              ) : (
                <Typography align="center" my={4}>
                  No links available
                </Typography>
              )
            )}
          </Container>
        </Grid>
        <Grid item xs={2}>  </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" my={4}>
            <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </Button>
            <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>

      {apiError && (
        <Box mt={2}>
          <Typography color="error" align="center">
            {apiError.message}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Explorerpage;
