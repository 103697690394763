import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from "@mui/material";
import { AuthProvider } from './context/AuthContext.js';
import Login from "./pages/Login.jsx";
import LandingPage from './pages/landing-page/LandingPage.tsx';
import UsernameProfile from './pages/Profile/UserProfilePage.jsx';
import ExplorerPage from './pages/Explorerpage.jsx';
import Theme from './components/Theme.jsx';
import PrivateRoute from './utils/PrivateRoute.js';
import PrimarySearchAppBar from './components/Navbar.jsx';
import Register from './pages/Register.jsx';
import CreateLink from './components/CreateLink.jsx';
import ResultPage from './pages/Search';
import CookieConsent, { Cookies } from "react-cookie-consent";
import UserSettings from './pages/Profile/UserSettings.jsx';

function App() {
  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={Theme}>
          <AuthProvider>
            <PrimarySearchAppBar />
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/search" element={<ResultPage />} />
              <Route path="/explorer" element={<ExplorerPage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile/:username" element={<PrivateRoute><UserSettings /></PrivateRoute>} />
              <Route path="/app/createlink" element={<PrivateRoute><CreateLink /></PrivateRoute>} />
              <Route path="/:username" element={<UsernameProfile />} />
            </Routes>
          </AuthProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
