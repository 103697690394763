import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import api from '../context/ApiInstance';
import { useState } from 'react';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

export default function SearchBar() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleInputChange = (event, newInputValue) => {
    setQuery(newInputValue);
  };

  const manipulatedResults = results.map((option) => ({
    label: option.user.username || '', // Ensure label is a string
  }));

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await handleSearchSubmit(query);
      navigate(`/search?query=${query}`);
    }
  };

  const handleSearchSubmit = async (searchQuery) => {
    setQuery(searchQuery); // Update the query state with the new value
    try {
      const response = await api.get(`profile/search/?search=${searchQuery}`);
      setResults(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Search>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        inputValue={query} // use inputValue instead of value
        onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
        onKeyDown={handleKeyDown}
        getOptionLabel={(option) => option.label || ''} // Ensure getOptionLabel returns a string
        options={manipulatedResults}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: '200px' }}
            label="Search input"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Search>
  );
}
