import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import api from '../../context/ApiInstance';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Input = styled('input')({
  display: 'none',
});

const UserSettings = () => {
  const { authTokens, logoutUser, user } = useContext(AuthContext);
  const [settings, setSettings] = useState({
    bio: '',
    about_me: '',
    image: null,
    image_titel: null,
  });
  const [preview, setPreview] = useState({
    image: null,
    image_titel: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        api.interceptors.request.use((config) => {
          if (authTokens) {
            config.headers.Authorization = `Bearer ${authTokens.access}`;
          }
          return config;
        });

        const response = await api.get('profile/settings/');
        setSettings(response.data);
        setPreview({
          image: response.data.image,
          image_titel: response.data.image_titel,
        });
      } catch (error) {
        setApiError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, [authTokens]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setSettings({ ...settings, [name]: files[0] });
      setPreview({ ...preview, [name]: URL.createObjectURL(files[0]) });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    formData.append('bio', settings.bio);
    formData.append('about_me', settings.about_me);
    if (settings.image instanceof File) {
      formData.append('image', settings.image);
    }
    if (settings.image_titel instanceof File) {
      formData.append('image_titel', settings.image_titel);
    }

    try {
      const response = await api.put('profile/settings/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSettings(response.data);
      setPreview({
        image: response.data.image,
        image_titel: response.data.image_titel,
      });
      navigate(`/${user.username}`);
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    setIsLoading(true);
    try {
      await api.delete('profile/settings/');
      logoutUser();
      navigate('/');
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="center" mt={2} mb={4}>
        <Avatar
          alt={settings.username}
          src={preview.image || '/static/images/avatar/1.jpg'}
          sx={{ width: 150, height: 150 }}
        />
        <Typography variant="h5" component="h1" sx={{ ml: 2 }}>
          {settings.username}
        </Typography>
      </Box>

      {isLoading && <div>Loading...</div>}
      {apiError && <div>Error: {apiError.message}</div>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Bio"
          name="bio"
          value={settings.bio}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="About Me"
          name="about_me"
          value={settings.about_me}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <Box display="flex" justifyContent="center" mt={2} mb={4}>
          <label htmlFor="image">
            <Input
              accept="image/*"
              id="image"
              name="image"
              type="file"
              onChange={handleFileChange}
            />
            <Button variant="contained" component="span" sx={{ mr: 2 }}>
              Profile
            </Button>
          </label>
          <label htmlFor="image_titel">
            <Input
              accept="image/*"
              id="image_titel"
              name="image_titel"
              type="file"
              onChange={handleFileChange}
            />
            <Button variant="contained" component="span">
              Background
            </Button>
          </label>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
            Save
          </Button>
          <Button variant="contained" color="secondary" sx={{ mt: 3, mb: 2, ml: 2 }} onClick={handleDeleteAccount}>
            Delete Account
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default UserSettings;